// FileUpload.js
import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase-config'; // Adjust the path if your file structure is different
import { collection, addDoc } from 'firebase/firestore';

function FileUpload() {
    const [file, setFile] = useState(null);
    const [metadata, setMetadata] = useState({
        name: '',
        type: '',
        version: '',
    });
    const [status, setStatus] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);

    const auth = getAuth();

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setStatus('');
        setUploadProgress(0);
    };

    const handleMetadataChange = (e) => {
        setMetadata({
            ...metadata,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpload = async () => {
        if (!file) {
            setStatus('Please select a file.');
            return;
        }

        try {
            const user = auth.currentUser;
            if (!user) {
                setStatus('User not authenticated.');
                return;
            }

            // Get the ID token
            const idToken = await user.getIdToken();

            // Prepare form data
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);

            // Append metadata
            formData.append('name', metadata.name);
            formData.append('type', metadata.type);
            formData.append('version', metadata.version);

            // Get the Worker URL from environment variables
            const workerUrl = process.env.REACT_APP_WORKER_URL;

            // Create XMLHttpRequest
            const xhr = new XMLHttpRequest();

            xhr.open('POST', workerUrl);

            // Set the Authorization header
            xhr.setRequestHeader('Authorization', `Bearer ${idToken}`);

            // Upload progress event
            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    const percentCompleted = Math.round((event.loaded * 100) / event.total);
                    setUploadProgress(percentCompleted);
                }
            });

            // Response handler
            xhr.onreadystatechange = async () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        const responseData = JSON.parse(xhr.responseText);
                        const { fileKey } = responseData;

                        // Save metadata to Firestore
                        await addDoc(collection(db, 'files'), {
                            uid: user.uid,
                            fileKey: fileKey,
                            name: metadata.name,
                            type: metadata.type,
                            version: metadata.version,
                            uploadDate: new Date(),
                        });

                        setStatus('File uploaded successfully.');
                        setUploadProgress(0); // Reset progress
                        // Reset the form
                        setFile(null);
                        setMetadata({ name: '', type: '', version: '' });
                    } else {
                        const errorText = xhr.responseText || xhr.statusText;
                        setStatus('Upload failed: ' + errorText);
                    }
                }
            };

            // Error handling
            xhr.onerror = () => {
                setStatus('An error occurred during the upload.');
            };

            // Send the request
            xhr.send(formData);
        } catch (error) {
            setStatus('Error: ' + error.message);
        }
    };

    return (
        <div>
            <h2>Upload File</h2>
            <div>
                <label>File:</label>
                <input type="file" onChange={handleFileChange} />
            </div>
            <div>
                <label>Name:</label>
                <input type="text" name="name" value={metadata.name} onChange={handleMetadataChange} />
            </div>
            <div>
                <label>Type:</label>
                <input type="text" name="type" value={metadata.type} onChange={handleMetadataChange} />
            </div>
            <div>
                <label>Version:</label>
                <input type="text" name="version" value={metadata.version} onChange={handleMetadataChange} />
            </div>
            <button onClick={handleUpload}>Upload</button>
            {uploadProgress > 0 && (
                <div>
                    <p>Uploading: {uploadProgress}%</p>
                    <progress value={uploadProgress} max="100" />
                </div>
            )}
            <p>{status}</p>
        </div>
    );
}

export default FileUpload;
