// UploadPage.js
import React, { useState } from 'react';
import FileUpload from '../../components/FileUpload';
import FileList from '../../components/FileList';

function UploadPage() {
    const [showUploadForm, setShowUploadForm] = useState(false);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Your Files</h1>
                <button onClick={() => setShowUploadForm(!showUploadForm)}>
                    {showUploadForm ? 'Close' : 'Upload New File'}
                </button>
            </div>
            {showUploadForm && <FileUpload />}
            <FileList />
        </div>
    );
}

export default UploadPage;
