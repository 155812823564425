// Navbar.js
import React, {useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import '../styles/Navbar.css'; // Import the CSS file
import {AuthContext} from './AuthContext';
import {getAuth, signOut} from 'firebase/auth';

function Navbar() {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                console.log('User signed out.');
                navigate('/');
            })
            .catch((error) => {
                console.error('Error signing out:', error);
            });
    };

    // Function to get the first letter of the user's email
    const getFirstLetter = (email) => {
        return email ? email.charAt(0).toUpperCase() : '';
    };

    // Function to generate a color based on a string
    const stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return '#' + ((hash & 0x00FFFFFF).toString(16).padStart(6, '0'));
    };

    return (
        <nav className="navbar">
            <div className="navbar-left">
                <ul className="navbar-menu">
                    {currentUser && <li><Link to="/dashboard">Dashboard</Link></li>}
                </ul>
            </div>
            {currentUser && (
                <div className="navbar-right">
                    <div
                        className="user-avatar"
                        style={{
                            backgroundColor: stringToColor(getFirstLetter(currentUser.email)),
                        }}
                    >
                        {getFirstLetter(currentUser.email)}
                    </div>
                    <a href="#" onClick={(e) => { e.preventDefault(); handleLogout(); }} className="logout-link">Logout</a>
                </div>
            )}
        </nav>
    );
}

export default Navbar;
