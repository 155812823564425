// Update.js
import React, { useState } from 'react';

function Update() {
    const [step, setStep] = useState(1);

    const nextStep = () => {
        setStep((prev) => prev + 1);
    };

    const prevStep = () => {
        setStep((prev) => prev - 1);
    };

    return (
        <div>
            <h2>Update Wizard</h2>
            {step === 1 && (
                <div>
                    <h3>Step 1: Select Device</h3>
                    {/* Step 1 content goes here */}
                    <button onClick={nextStep}>Next</button>
                </div>
            )}
            {step === 2 && (
                <div>
                    <h3>Step 2: Configure Settings</h3>
                    {/* Step 2 content goes here */}
                    <button onClick={prevStep}>Back</button>
                    <button onClick={nextStep}>Next</button>
                </div>
            )}
            {step === 3 && (
                <div>
                    <h3>Step 3: Review and Confirm</h3>
                    {/* Step 3 content goes here */}
                    <button onClick={prevStep}>Back</button>
                    <button onClick={() => alert('Update Complete')}>Finish</button>
                </div>
            )}
        </div>
    );
}

export default Update;
