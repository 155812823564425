// Details.js
import React, { useEffect, useState } from 'react';

function Details() {
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        // Mock device data
        const mockDevices = [
            { id: 1, name: 'Device A', status: 'Alive' },
            { id: 2, name: 'Device B', status: 'Dead' },
            { id: 3, name: 'Device C', status: 'Alive' },
        ];

        setDevices(mockDevices);

        // If fetching from API:
        // fetch('/api/devices')
        //     .then((response) => response.json())
        //     .then((data) => setDevices(data))
        //     .catch((error) => console.error('Error fetching devices:', error));
    }, []);

    return (
        <div>
            <h2>Device Details</h2>
            <table className="device-table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {devices.map((device) => (
                    <tr key={device.id}>
                        <td>{device.id}</td>
                        <td>{device.name}</td>
                        <td>{device.status}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default Details;
