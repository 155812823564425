// Dashboard.js
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import '../styles/global.css'; // Ensure this CSS file is correctly imported
import '../styles/Dashboard.css'; // Ensure this CSS file is correctly imported

function Dashboard() {
    return (
        <div className="dashboard-container">
            <aside className="sidebar">
                <nav>
                    <ul>
                        <li>
                            <Link to="details">Details</Link>
                        </li>
                        <li>
                            <Link to="update">Update</Link>
                        </li>
                        <li>
                            <Link to="upload">Upload Versions</Link>
                        </li>
                    </ul>
                </nav>
            </aside>
            <main className="dashboard-content">
                <Outlet />
            </main>
        </div>
    );
}

export default Dashboard;
