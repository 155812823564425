// FileList.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import {
    collection,
    query,
    where,
    onSnapshot,
    doc,
    deleteDoc,
    updateDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

function FileList() {
    const [files, setFiles] = useState([]);
    const [editingFile, setEditingFile] = useState(null);
    const [editMetadata, setEditMetadata] = useState({ name: '', type: '', version: '' });
    const auth = getAuth();

    useEffect(() => {
        const user = auth.currentUser;
        if (!user) return;

        // Real-time listener
        const q = query(collection(db, 'files'), where('uid', '==', user.uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const filesData = [];
            snapshot.forEach((doc) => {
                filesData.push({ id: doc.id, ...doc.data() });
            });
            setFiles(filesData);
        });

        // Cleanup on unmount
        return () => unsubscribe();
    }, [auth]);

    const handleEdit = (file) => {
        setEditingFile(file);
        setEditMetadata({
            name: file.name,
            type: file.type,
            version: file.version,
        });
    };

    const handleEditChange = (e) => {
        setEditMetadata({
            ...editMetadata,
            [e.target.name]: e.target.value,
        });
    };

    const handleSaveEdit = async () => {
        try {
            const fileDocRef = doc(db, 'files', editingFile.id);
            await updateDoc(fileDocRef, {
                name: editMetadata.name,
                type: editMetadata.type,
                version: editMetadata.version,
            });
            setEditingFile(null);
            console.log('Metadata updated successfully.');
        } catch (error) {
            console.error('Error updating metadata:', error);
        }
    };

    const handleDelete = async (file) => {
        if (window.confirm('Are you sure you want to delete this file?')) {
            try {
                const user = auth.currentUser;
                const idToken = await user.getIdToken();

                // Get the Worker URL from environment variables
                const workerUrl = process.env.REACT_APP_WORKER_URL;

                // Delete the file from R2 via your Worker
                const response = await fetch(`${workerUrl}/delete`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileKey: file.fileKey }),
                });

                if (response.ok) {
                    // Delete metadata from Firestore
                    await deleteDoc(doc(db, 'files', file.id));
                    console.log('File deleted successfully.');
                } else {
                    const errorText = await response.text();
                    console.error('Delete failed:', errorText);
                }
            } catch (error) {
                console.error('Error deleting file:', error);
            }
        }
    };

    return (
        <div>
            <h2>Your Files</h2>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Version</th>
                    <th>Upload Date</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {files.map((file) => (
                    <tr key={file.id}>
                        {editingFile && editingFile.id === file.id ? (
                            <>
                                <td>
                                    <input
                                        type="text"
                                        name="name"
                                        value={editMetadata.name}
                                        onChange={handleEditChange}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="type"
                                        value={editMetadata.type}
                                        onChange={handleEditChange}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="version"
                                        value={editMetadata.version}
                                        onChange={handleEditChange}
                                    />
                                </td>
                                <td>{new Date(file.uploadDate.seconds * 1000).toLocaleString()}</td>
                                <td>
                                    <button onClick={handleSaveEdit}>Save</button>
                                    <button onClick={() => setEditingFile(null)}>Cancel</button>
                                </td>
                            </>
                        ) : (
                            <>
                                <td>{file.name}</td>
                                <td>{file.type}</td>
                                <td>{file.version}</td>
                                <td>{new Date(file.uploadDate.seconds * 1000).toLocaleString()}</td>
                                <td>
                                    <button onClick={() => handleEdit(file)}>Edit</button>
                                    <button onClick={() => handleDelete(file)}>Delete</button>
                                </td>
                            </>
                        )}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default FileList;
