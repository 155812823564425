// src/App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import ErrorBoundary from './components/ErrorBoundary';
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./components/PrivateRoute";
import Details from "./pages/dashboard/Details";
import Update from "./pages/dashboard/Update";
import { AuthContext } from './components/AuthContext';
import './styles/global.css';
import UploadPage from "./pages/dashboard/UploadPage";

function App() {
    const { currentUser, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <ErrorBoundary>
                {currentUser && <Navbar />}
                <div className="content">
                    <Routes>
                        <Route path="/" element={<Auth />} />
                        <Route
                            path="/dashboard/*"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        >
                            <Route path="details" element={<Details />} />
                            <Route path="update" element={<Update />} />
                            <Route path="upload" element={<UploadPage />} />
                        </Route>
                    </Routes>
                </div>
            </ErrorBoundary>
        </Router>
    );
}

export default App;