// src/pages/Auth.js
import React, { useState } from "react";
import { auth } from "../firebase-config";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
} from "firebase/auth";
import "../styles/Auth.css"; // CSS file for styling
import { FcGoogle } from "react-icons/fc"; // For Google Icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Auth = () => {
    const [isRegister, setIsRegister] = useState(false); // Set initial state to false for login
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const navigate = useNavigate(); // Initialize navigate function

    const googleProvider = new GoogleAuthProvider();

    const handleAuth = async (e) => {
        e.preventDefault(); // Prevent default form submission

        if (isRegister) {
            if (password !== confirmPassword) {
                setError("Passwords do not match!");
                return;
            }

            try {
                await createUserWithEmailAndPassword(auth, email, password);
                setSuccess("Account created successfully!");
                setError("");
                setEmail("");
                setPassword("");
                setConfirmPassword("");
                navigate('/dashboard'); // Navigate to dashboard after successful registration
            } catch (err) {
                setError(err.message);
                setSuccess("");
            }
        } else {
            try {
                await signInWithEmailAndPassword(auth, email, password);
                setSuccess("Logged in successfully!");
                setError("");
                setEmail("");
                setPassword("");
                navigate('/dashboard'); // Navigate to dashboard after successful login
            } catch (err) {
                setError(err.message);
                setSuccess("");
            }
        }
    };

    const handleGoogleLogin = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            setSuccess("Logged in successfully with Google!");
            setError("");
            navigate('/dashboard'); // Navigate to dashboard after Google login
        } catch (err) {
            setError("Google login failed. Please try again.");
            setSuccess("");
        }
    };

    return (
        <div className="auth-wrapper">
            <div className="auth-container">
                <h1>{isRegister ? "Create an Account" : "Login"}</h1>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}

                <form onSubmit={handleAuth}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                    </div>

                    {isRegister && (
                        <div className="form-group">
                            <label htmlFor="confirm-password">Confirm Password</label>
                            <input
                                type="password"
                                id="confirm-password"
                                placeholder="Confirm your password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                autoComplete="new-password"
                            />
                        </div>
                    )}

                    <button type="submit" className="auth-button">
                        {isRegister ? "Register" : "Login"}
                    </button>
                </form>

                <div className="divider">or</div>

                <button onClick={handleGoogleLogin} className="google-button">
                    <FcGoogle className="google-icon" />
                    Sign {isRegister ? "Up" : "In"} with Google
                </button>

                <p onClick={() => setIsRegister(!isRegister)} className="toggle-auth">
                    {isRegister ? "Already have an account? Login" : "Don't have an account? Register"}
                </p>
            </div>
        </div>
    );
};

export default Auth;